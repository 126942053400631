import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'app/common/constants';
import { CommonDataService } from 'app/common/services';
import { ICategoryTreeFilterClientDto } from 'app/components/category-tree-view';
import { ICountryDto } from 'app/models/country';

@Component({
    templateUrl: './grid-tree.component.html',
    styleUrls: ['./grid-tree.component.scss']
})
export class GridTreeComponent implements OnInit {
    public filterDefault: ICategoryTreeFilterClientDto = { searchValue: '', expanded: false, expandedIds: [], countryIds: undefined, showAll: false };
    public filter: ICategoryTreeFilterClientDto = { ...this.filterDefault };
    public countries: Array<ICountryDto>;

    public set expandedIds(value) {
        this.filter.expandedIds = value;
    }

    constructor(private readonly route: ActivatedRoute, private readonly commonDataService: CommonDataService, private readonly router: Router) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            let expandedIds = [];
            if (Array.isArray(params.expandedIds)) {
                expandedIds = params.expandedIds.map((expandedId) => +expandedId);
            } else {
                expandedIds = params.expandedIds ? [+params.expandedIds] : this.filterDefault.expandedIds;
            }
            let countryIds = [];
            if (Array.isArray(params.countryIds)) {
                countryIds = params.countryIds.map((countryId) => +countryId);
            } else {
                countryIds = params.countryIds ? [+params.countryIds] : this.filterDefault.countryIds;
            }
            this.filter = {
                ...this.filter,
                expanded: params.expanded === 'true',
                searchValue: params.searchValue || this.filterDefault.searchValue,
                expandedIds,
                countryIds: countryIds
            };
        });
        this.commonDataService.country.getTranslationCountries().subscribe((countries) => (this.countries = countries));
    }

    public returnQuery() {
        let filter = new HttpParams();
        if (this.filter.expanded !== undefined) {
            filter = filter.set('expanded', this.filter.expanded + '');
        }
        if (this.filter.searchValue) {
            filter = filter.set('searchValue', this.filter.searchValue + '');
        }
        this.filter.expandedIds?.forEach((expandedId) => (filter = filter.append('expandedIds', expandedId + '')));
        this.filter.countryIds?.forEach((countryId) => (filter = filter.append('countryIds', countryId + '')));
        return filter.toString();
    }

    public clearFilter() {
        this.filter = { ...this.filterDefault };
        this.search();
    }

    public search() {
        this.router.navigate(['/', 'category'], { queryParams: this.filter }).catch(EMPTY);
    }

    public openDetail(id: number) {
        this.router.navigate(['/', 'category', 'tree', id], { queryParams: { returnQuery: this.returnQuery() } }).catch(EMPTY);
    }
}
